.ListWithParameters {
  .bp3-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      margin-bottom: 30px;
    }

    .select-element {
      position: relative;
      width: 60%;
      margin-bottom: 30px;

      & > span {
        width: 100%;

        button {
          width: 100%;
        }
      }

      .bp3-overlay  {
        .bp3-transition-container, .bp3-transition-container .bp3-popover {
          width: 100%;
        }

        .bp3-menu {
          max-width: 100%;
        }
      }
    }

    .add-element-button {
      margin-top: 30px;
    }
  }
}