/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */

$black: #10161a;

$dark-gray1: #182026;
$dark-gray2: #202b33;
$dark-gray3: #293742;
$dark-gray4: #30404d;
$dark-gray5: #394b59;

$gray1: #5c7080;
$gray2: #738694;
$gray3: #8a9ba8;
$gray4: #a7b6c2;
$gray5: #bfccd6;

$light-gray1: #ced9e0;
$light-gray2: #d8e1e8;
$light-gray3: #e1e8ed;
$light-gray4: #ebf1f5;
$light-gray5: #f5f8fa;

$white: #ffffff;

$blue1: #0e5a8a;
$blue2: #106ba3;
$blue3: #137cbd;
$blue4: #2b95d6;
$blue5: #48aff0;

$green1: #0a6640;
$green2: #0d8050;
$green3: #0f9960;
$green4: #15b371;
$green5: #3dcc91;

$orange1: #a66321;
$orange2: #bf7326;
$orange3: #d9822b;
$orange4: #f29d49;
$orange5: #ffb366;

$red1: #a82a2a;
$red2: #c23030;
$red3: #db3737;
$red4: #f55656;
$red5: #ff7373;

$vermilion1: #9e2b0e;
$vermilion2: #b83211;
$vermilion3: #d13913;
$vermilion4: #eb532d;
$vermilion5: #ff6e4a;

$rose1: #a82255;
$rose2: #c22762;
$rose3: #db2c6f;
$rose4: #f5498b;
$rose5: #ff66a1;

$violet1: #5c255c;
$violet2: #752f75;
$violet3: #8f398f;
$violet4: #a854a8;
$violet5: #c274c2;

$indigo1: #5642a6;
$indigo2: #634dbf;
$indigo3: #7157d9;
$indigo4: #9179f2;
$indigo5: #ad99ff;

$cobalt1: #1f4b99;
$cobalt2: #2458b3;
$cobalt3: #2965cc;
$cobalt4: #4580e6;
$cobalt5: #669eff;

$turquoise1: #008075;
$turquoise2: #00998c;
$turquoise3: #00b3a4;
$turquoise4: #14ccbd;
$turquoise5: #2ee6d6;

$forest1: #1d7324;
$forest2: #238c2c;
$forest3: #29a634;
$forest4: #43bf4d;
$forest5: #62d96b;

$lime1: #728c23;
$lime2: #87a629;
$lime3: #9bbf30;
$lime4: #b6d94c;
$lime5: #d1f26d;

$gold1: #a67908;
$gold2: #bf8c0a;
$gold3: #d99e0b;
$gold4: #f2b824;
$gold5: #ffc940;

$sepia1: #63411e;
$sepia2: #7d5125;
$sepia3: #96622d;
$sepia4: #b07b46;
$sepia5: #c99765;

$pt-intent-primary: $blue3;
$pt-intent-success: $green3;
$pt-intent-warning: $orange3;
$pt-intent-danger: $red3;

$pt-app-background-color: $light-gray5;
$pt-dark-app-background-color: $dark-gray3;

$pt-outline-color: rgba($blue3, 0.6);

$pt-text-color: $dark-gray1;
$pt-text-color-muted: $gray1;
$pt-text-color-disabled: rgba($pt-text-color-muted, 0.5);
$pt-heading-color: $pt-text-color;
$pt-link-color: $blue2;
$pt-dark-text-color: $light-gray5;
$pt-dark-text-color-muted: $gray5;
$pt-dark-text-color-disabled: rgba($pt-dark-text-color-muted, 0.5);
$pt-dark-heading-color: $pt-dark-text-color;
$pt-dark-link-color: $blue5;
$pt-text-selection-color: rgba(125, 188, 255, 0.6);

$pt-icon-color: $pt-text-color-muted;
$pt-icon-color-hover: $pt-text-color;
$pt-icon-color-disabled: $pt-text-color-disabled;
$pt-icon-color-selected: $pt-intent-primary;
$pt-dark-icon-color: $pt-dark-text-color-muted;
$pt-dark-icon-color-hover: $pt-dark-text-color;
$pt-dark-icon-color-disabled: $pt-dark-text-color-disabled;
$pt-dark-icon-color-selected: $pt-intent-primary;

$pt-divider-black: rgba($black, 0.15);
$pt-dark-divider-black: rgba($black, 0.4);
$pt-dark-divider-white: rgba($white, 0.15);

$pt-code-text-color: $pt-text-color-muted;
$pt-dark-code-text-color: $pt-dark-text-color-muted;
$pt-code-background-color: rgba($white, 0.7);
$pt-dark-code-background-color: rgba($black, 0.3);
$ns: bp3;

$pt-grid-size: 10px;

$pt-font-family: -apple-system,
                 "BlinkMacSystemFont",
                 "Segoe UI",
                 "Roboto",
                 "Oxygen",
                 "Ubuntu",
                 "Cantarell",
                 "Open Sans",
                 "Helvetica Neue",
                 "Icons16",                  sans-serif;

$pt-font-family-monospace: monospace;

$pt-font-size: $pt-grid-size * 1.4;
$pt-font-size-large: $pt-grid-size * 1.6;
$pt-font-size-small: $pt-grid-size * 1.2;

$pt-line-height: ($pt-grid-size * 1.8) / $pt-font-size + 0.0001;

$icons16-family: "Icons16";
$icons20-family: "Icons20";

$pt-icon-size-standard: 16px;
$pt-icon-size-large: 20px;

$pt-border-radius: floor($pt-grid-size / 3);

$pt-button-height: $pt-grid-size * 3;
$pt-button-height-small: $pt-grid-size * 2.4;
$pt-button-height-smaller: $pt-grid-size * 2;
$pt-button-height-large: $pt-grid-size * 4;

$pt-input-height: $pt-grid-size * 3;
$pt-input-height-large: $pt-grid-size * 4;
$pt-input-height-small: $pt-grid-size * 2.4;

$pt-navbar-height: $pt-grid-size * 5;

$pt-z-index-base: 0;
$pt-z-index-content: $pt-z-index-base + 10;
$pt-z-index-overlay: $pt-z-index-content + 10;

$pt-border-shadow-opacity: 0.1;
$pt-drop-shadow-opacity: 0.2;
$pt-dark-border-shadow-opacity: $pt-border-shadow-opacity * 2;
$pt-dark-drop-shadow-opacity: $pt-drop-shadow-opacity * 2;

$pt-elevation-shadow-0: 0 0 0 1px $pt-divider-black,
                        0 0 0 rgba($black, 0),
                        0 0 0 rgba($black, 0);
$pt-elevation-shadow-1: 0 0 0 1px rgba($black, $pt-border-shadow-opacity),
                        0 0 0 rgba($black, 0),
                        0 1px 1px rgba($black, $pt-drop-shadow-opacity);
$pt-elevation-shadow-2: 0 0 0 1px rgba($black, $pt-border-shadow-opacity),
                        0 1px 1px rgba($black, $pt-drop-shadow-opacity),
                        0 2px 6px rgba($black, $pt-drop-shadow-opacity);
$pt-elevation-shadow-3: 0 0 0 1px rgba($black, $pt-border-shadow-opacity),
                        0 2px 4px rgba($black, $pt-drop-shadow-opacity),
                        0 8px 24px rgba($black, $pt-drop-shadow-opacity);
$pt-elevation-shadow-4: 0 0 0 1px rgba($black, $pt-border-shadow-opacity),
                        0 4px 8px rgba($black, $pt-drop-shadow-opacity),
                        0 18px 46px 6px rgba($black, $pt-drop-shadow-opacity);

$pt-dark-elevation-shadow-0: 0 0 0 1px $pt-dark-divider-black,
                             0 0 0 rgba($black, 0),
                             0 0 0 rgba($black, 0);
$pt-dark-elevation-shadow-1: 0 0 0 1px rgba($black, $pt-dark-border-shadow-opacity),
                             0 0 0 rgba($black, 0),
                             0 1px 1px rgba($black, $pt-dark-drop-shadow-opacity);
$pt-dark-elevation-shadow-2: 0 0 0 1px rgba($black, $pt-dark-border-shadow-opacity),
                             0 1px 1px rgba($black, $pt-dark-drop-shadow-opacity),
                             0 2px 6px rgba($black, $pt-dark-drop-shadow-opacity);
$pt-dark-elevation-shadow-3: 0 0 0 1px rgba($black, $pt-dark-border-shadow-opacity),
                             0 2px 4px rgba($black, $pt-dark-drop-shadow-opacity),
                             0 8px 24px rgba($black, $pt-dark-drop-shadow-opacity);
$pt-dark-elevation-shadow-4: 0 0 0 1px rgba($black, $pt-dark-border-shadow-opacity),
                             0 4px 8px rgba($black, $pt-dark-drop-shadow-opacity),
                             0 18px 46px 6px rgba($black, $pt-dark-drop-shadow-opacity);

$pt-transition-ease: cubic-bezier(0.4, 1, 0.75, 0.9);
$pt-transition-ease-bounce: cubic-bezier(0.54, 1.12, 0.38, 1.11);
$pt-transition-duration: 100ms;

$pt-input-box-shadow: inset 0 0 0 1px rgba($black, 0.15),
                      inset 0 1px 1px rgba($black, $pt-drop-shadow-opacity);

$pt-dialog-box-shadow: $pt-elevation-shadow-4;
$pt-popover-box-shadow: $pt-elevation-shadow-3;
$pt-tooltip-box-shadow: $pt-popover-box-shadow;

$pt-dark-input-box-shadow: inset 0 0 0 1px rgba($black, 0.3),
                           inset 0 1px 1px rgba($black, $pt-dark-drop-shadow-opacity);

$pt-dark-dialog-box-shadow: $pt-dark-elevation-shadow-4;
$pt-dark-popover-box-shadow: $pt-dark-elevation-shadow-3;
$pt-dark-tooltip-box-shadow: $pt-dark-popover-box-shadow;
