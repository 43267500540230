@import "~@blueprintjs/core/lib/scss/variables";

$inactive-editor-color: $light-gray1;
$active-editor-color: $white;

$editor-padding: 10px;
$error-border-width: 2px;

.GraphTextEditor {
  position: relative;

  &.invalid-input {
    textarea.bp3-input {
      padding: $editor-padding - $error-border-width;
      border: $red3 solid $error-border-width;
    }

    .text-editor-error {
      opacity: 1;
    }
  }

  textarea.bp3-input {
    padding: $editor-padding;

    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: $inactive-editor-color;

    resize: none;

    transition: color 200ms ease-in-out;
    background-color: $gray1;

    &:focus, &:hover {
      color: $active-editor-color;
    }
  }

  .text-editor-error {
    position: absolute;
    width: 100%;

    bottom: 0;

    opacity: 0;
    transition: opacity 200ms ease-in-out;

    .bp3-card {
      position: absolute;
      width: 90%;
      height: 100px;

      bottom: 20px;
      left: 5%;
      margin-left: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: $red3;
    }
  }
}