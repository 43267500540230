@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/table/lib/css/table.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~@blueprintjs/core/lib/scss/variables";


$components-border: solid 1px $light-gray1;

$small-query: "only screen and (max-width: 991px)";

html, body {
  height: 100%;
  margin: 0;
}

#main {
  height: 100%;
  overflow: hidden;

  @media #{$small-query} {
    overflow: auto;
  }
}

#main-container {
  display: flex;

  box-sizing: border-box;
  height: 100%;

  @media #{$small-query} {
    flex-wrap: wrap;
  }

  & > .row {
    height: 100%;
  }

  #graph-col, #editor-col {
    height: 100%;
    padding: 0;
  }

  #graph-col {
    display: flex;
    flex-direction: column;

    width: 70%;

    @media #{$small-query} {
      width: 100%;
    }

    .GraphVisualization {
      display: flex;
      width: 100%;
      height: 70%;

      flex-direction: column;

      border-bottom: $components-border;
      position: relative;

      .Cytoscape {
        height: 100%;
      }
    }
    .GraphTable {
      height: 30%;

      overflow-y: auto;
    }
  }

  #editor-col {
    width: 30%;
    display: flex;
    flex-direction: column;

    @media #{$small-query} {
      width: 100%;
    }

    *:focus {
      outline: none;
      box-shadow: none;
    }

    #direction-option {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0.25rem;

      .bp3-switch {
        user-select: none;
        margin: 0;
      }
    }

    .GraphTextEditor {
      flex-grow: 100;
    }
  }
}

.bp3-dark .bp3-navbar {
  $graph-toolbar-height: 45px;
  height: $graph-toolbar-height;

  flex: 0 0 auto;

  box-shadow: none;

  .bp3-navbar-group {
    height: $graph-toolbar-height - 1px;

    .bp3-control {
      margin-bottom: 0;
    }
  }

  .bp3-card {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.bp3-overlay > .bp3-card {
  width: 800px;
  left: 50%;
  margin-left: -400px;

  height: 80%;
  top: 10%;

  @media(max-width: 800px) {
    width: 100%;
    left: 0;
    margin-left: 0;
  }
}

.cyto-n {
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 1em;

  &.id {
    font-size: 5px;
    font-weight: 700;
    color: #fafafa;
  }

  &.description {
    font-size: 10px;
    margin: 0 0 1px;
  }

  &.centrality {
    font-size: 5px;
    color: #333333;
  }
}

.tab-pane.generators {
  input {
    width: 75px;
  }
}

.centralities-to-calculate {
  .centrality-checkbox {
    padding-right: 0;
    text-align: right;


    div {
      margin-right: 0;
    }
  }
}

.modal .centralities-table-modal {
  max-width: 95%;
}

.modal .help-modal {
  max-width: 90%;
}

.is-directed .custom-checkbox {
  margin-right: 0;
}

.customization-tabs {
  .tab-pane.active {
    margin-top: 1rem;
  }
}