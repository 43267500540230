.GraphToolbar {
  .bp3-input-group, .bp3-control-group {
    margin-right: 5px;
  }

  .bp3-input-group {
    width: 150px;
  }

  .bp3-control-group .bp3-input-group {
    margin-right: 0;
  }
}